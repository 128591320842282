/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import { globalHistory } from "@reach/router";

globalHistory.listen(({ location }) => {
  console.log("location changed", location);
});
